import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";

import { useTranslation } from "react-i18next";
import { useAppContext } from "contexts/AppContext";

import moment from "moment";
import { withFormik } from "formik";

import { useNotification } from "stories/components/Notification";
import { useAuthContext } from "contexts/AuthContext";

import Calendar from "react-calendar";
import "stories/components/calendar.css";

import { Button, Typography, Modal } from "stories/components";

import { Input } from "stories/forms";

import { Container, Row, Col } from "stories/layout";

import { httpGetTherapist } from "services/users";
import {
  httpGetEvents,
  httpUpdateEvent,
  httpDeleteEvent,
} from "services/events";
import { httpGetEnums } from "services/enums";

import Select from "react-select";

import logo from "assets/images/profileImg.png";
import geo from "assets/img/icons/common/geo-alt-fill.svg";
import free from "assets/img/icons/common/circle-fill.svg";
import kela from "assets/img/icons/common/kela.png";
import fi from "assets/img/icons/flags/fi.png";
import se from "assets/img/icons/flags/se.png";
import gb from "assets/img/icons/flags/GB.png";

import appConfig from "services/config";

const baseUrl = appConfig.apiUrl;

const datesAreOnSameDay = (first, second) => {
  if (moment(first).format("l") === moment(second).format("l")) return true;
};

const TherapistProfile = (props) => {
  const {
    profile,
    events,
    reservedEvents,
    selectedTime,
    setSelectedTime,
    therapyTypes,
    toWhos,
    setCalendarInfo,
    howLong,
    setHowLong,
    setsecondEvent,
    reseptionLocation,
    setReseptionLocation,
    usingKelaApprove,
    setUsingKelaApprove,
    selectedTherapy,
    setSelectedTherapy,
    history,
    loading,
  } = props;

  const { errors, handleSubmit, isSubmitting, setSubmitting } = props;

  const { enums } = useAppContext();
  const [date, setDate] = useState(new Date());
  const [timeList, setTimeList] = useState([]);
  const location = useLocation();
  const [therapiesList, setTherapiesList] = useState([]);

  const [modal, setModal] = useState(false);
  const [returnSuccessfulModal, setReturnSuccessfulModal] = useState(false);
  const [returnFailedModal, setReturnFailedModal] = useState(false);

  const [eventID, setEventID] = useState();
  const [eventToken, setEventToken] = useState();
  const [stripeSessionId, setStripeSessionId] = useState();

  useEffect(() => {
    setSubmitting(false);
  }, []);

  useEffect(() => {
    if (selectedTherapy !== undefined) {
      if (howLong !== undefined && selectedTime.length !== 0) {
        if (profile?.only_remote_appointments === true) {
          setSubmitting(true);
        } else {
          if (profile?.remote_appointment === false) {
            setSubmitting(true);
          }
          if (reseptionLocation !== undefined) {
            setSubmitting(true);
          }
        }
      }
    }
  }, [howLong, selectedTime, reseptionLocation, selectedTherapy]);

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleReturnSuccessfulModal = () => {
    setReturnSuccessfulModal(!returnSuccessfulModal);
  };

  const toggleReturn = ({ id }) => {
    history.push(`/dashboard/profile/${id}`);
  };

  const toggleReturnFailedModal = () => {
    setReturnFailedModal(!returnFailedModal);
  };

  useEffect(() => {
    generateTimeList();
    generateTherapiesList();
  }, [date, howLong, profile, enums]);

  const howLongList = [
    { value: 45, label: "45 min" },
    { value: 90, label: "90 min" },
  ];

  const howLongHandler = (event) => {
    setHowLong(event);
  };

  const reseptionLocationChoises = [
    { value: false, label: "Paikan päällä" },
    { value: true, label: "Etävastaanotto" },
  ];

  const receptionLocationHandler = (event) => {
    setReseptionLocation(event);
  };

  const compensationList = [
    { value: false, label: "-" },
    { value: true, label: "Kelan korvaus" },
  ];

  const compensationHandler = (event) => {
    setUsingKelaApprove(event);
  };

  const generateTherapiesList = () => {
    let _therapiesList = [];

    profile?.therapies?.map((t) =>
      _therapiesList.push({
        value: t,
        label: enums?.users.therapy.find((e) => e.key === t).value,
      })
    );

    setTherapiesList(_therapiesList);
  };

  const therapyHandler = (event) => {
    setSelectedTherapy(event);
  };

  const freeCalenderList = events
    .filter((day) => moment(day.start_time).isAfter(moment()))
    .map((d) => d.start_time);

  const tileContent = ({ date, view }) => {
    if (view === "month") {
      if (freeCalenderList.find((dDate) => datesAreOnSameDay(dDate, date))) {
        return <img className="calender_freeTab" src={free} alt="free time" />;
      }
    }
  };

  const tileDisabled = ({ date, view }) => {
    const dat = new Date();
    dat.setHours(0, 0, 0, 0);

    const padTo2Digits = (num) => {
      return num.toString().padStart(2, "0");
    };

    const formatDate = (dat) => {
      return [
        dat.getFullYear(),
        padTo2Digits(dat.getMonth() + 1),
        padTo2Digits(dat.getDate()),
      ].join("-");
    };

    if (view === "month") {
      return formatDate(date) < formatDate(new Date());
    }
  };

  const generateTimeList = () => {
    let _timeList = [];
    if (howLong?.value === 45) {
      events
        .filter((day) => moment(day.start_time).isAfter(moment()))
        .filter(
          (d) => moment(date).format("l") === moment(d.start_time).format("l")
        )
        .sort(
          (a, b) =>
            moment(a.start_time).format("LT") -
            moment(b.start_time).format("LT")
        )
        .map((e) => {
          _timeList.push({
            value: `${e.id}`,
            label: `${moment(e.start_time).format("LT")} - ${moment(e.end_time)
              .subtract(15, "minutes")
              .format("LT")}`,
            length: `${moment(e.end_time).diff(e.start_time, "minutes")}`,
          });
          return "";
        });

      setTimeList(_timeList);
    }

    if (howLong?.value === 90) {
      let sortedEvents = [];
      events
        .filter((day) => moment(day.start_time).isAfter(moment()))
        .filter(
          (d) => moment(date).format("l") === moment(d.start_time).format("l")
        )
        .sort(
          (a, b) =>
            moment(a.start_time).format("LT") -
            moment(b.start_time).format("LT")
        )
        .map((e) => {
          sortedEvents.push(e);
          return "";
        });

      sortedEvents.map((e, index) => {
        if (
          index < sortedEvents.length - 1 &&
          moment(e.end_time).format("LT") ===
            moment(sortedEvents[index + 1]?.start_time).format("LT")
        ) {
          // _timeList.push({ value: `${e.id}`, label: `${moment(e.start_time).format('LT')} - ${moment(sortedEvents[index+1]?.end_time).format('LT')}` })
          _timeList.push({
            value: `${e.id}`,
            label: `${moment(e.start_time).format("LT")} - ${moment(
              sortedEvents[index + 1]?.end_time
            )
              .subtract(30, "minutes")
              .format("LT")}`,
            length: `${moment(sortedEvents[index + 1]?.end_time).diff(
              e.start_time,
              "minutes"
            )}`,
          });
          return "";
        }
      });
      console.log("välilista", _timeList);
      setTimeList(_timeList);
    }
  };

  const dateHandler = (nextValue) => {
    setDate(nextValue);
    setSelectedTime([]);
  };

  const selectedTimeHandler = (value) => {
    setSelectedTime(value);
    if (howLong?.value === 90) {
      const cut = value.label.split(" ");
      const last = cut[cut.length - 1];
      let findSecondEvent = [];
      events
        .filter(
          (d) => moment(date).format("l") === moment(d.start_time).format("l")
        )
        .filter(
          (event) =>
            moment(event.end_time).subtract(30, "minutes").format("LT") === last
        )
        .map((o) => {
          findSecondEvent.push(o);
          return "";
        });
      setsecondEvent(findSecondEvent);
    }
  };

  const timeReducer = (value) => {
    if (value === undefined) {
    } else {
      const cut = value.split(" ");
      const first = cut[0];
      const testi = first.split(".");
      const alku = testi[0];
      const last = cut[cut.length - 1];
      const testi2 = last.split(".");
      const loppu = testi2[0];

      if (loppu - alku === 1) {
        return `${first} - ${last}`;
      } else {
        return `${first} - ${last}`;
      }
    }
  };

  const timeReducerTwoTimes = (alku, loppu) => {
    if (moment(loppu).format("HH") - moment(alku).format("HH") === 1) {
      return `${moment(alku).format("HH:mm")} - ${moment(loppu)
        .subtract(15, "minutes")
        .format("HH:mm")}`;
    } else {
      return `${moment(alku).format("HH:mm")} - ${moment(loppu)
        .subtract(30, "minutes")
        .format("HH:mm")}`;
    }
  };

  const submitHandler = () => {
    handleSubmit();
    toggleModal();
  };

  const cancelReservation = () => {
    let id = selectedTime.value;

    let data = {
      reserved: false,
      title: "Vapaa aika",
      description: "",
      use_kela: false,
      use_remote_appointment: false,
    };

    httpUpdateEvent(id, data);
    toggleModal();
  };

  const linkHandler = () => {
    const requestOptions = {
      method: "POST",
    };
    fetch(
      `${baseUrl}/events/${selectedTime?.value}/create_payment_link/`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((body) => (window.location.href = body.payment_url));
        // res.json().then(body => console.log(body.payment_url) )
      }
      // setReload(true)
    });
  };

  useEffect(() => {
    if (location?.search) {
      let path = location?.search;
      let osa = path?.split("token=");
      let osa2 = osa[1]?.split("&");

      let eventId = osa2[2].split("=")[1];
      setEventID(osa2[2].split("=")[1]);

      let eventTokeni = osa2[0];
      setEventToken(osa2[0]);

      let stripeSessio = osa2[1].split("=")[1];
      setStripeSessionId(osa2[1].split("=")[1]);

      const requestOptions = {
        method: "POST",
      };

      if (path.includes("success=true")) {
        fetch(
          `${baseUrl}/events/${eventId}/complete_event_reservation/?token=${eventTokeni}&stripe_session_id=${stripeSessio}`,
          requestOptions
        );
        toggleReturnSuccessfulModal();
      }

      if (path.includes("success=false")) {
        fetch(
          `${baseUrl}/events/${eventId}/cancel_event_reservation/?token=${eventTokeni}&stripe_session_id=${stripeSessio}`,
          requestOptions
        );
        toggleReturnFailedModal();
      }
    }
  }, [location]);

  // useEffect(() => {
  //   // console.log(
  //   //   events?.maps((event) =>
  //   //     moment(event.start_time).format("l").diff(event.end_time, "minutes") >
  //   //     46
  //   //       ? howLongList
  //   //       : howLongList[0]
  //   //   )
  //   // );
  //   // console.log(
  //   //   "timeList",
  //   //   timeList.map((t) => t.length == 120)
  //   // );
  //   // console.log("KOE", timeList);
  //   // console.log("howLong", !!howLong);
  //   console.log("date", date);
  //   console.log("selectedTime", selectedTime);
  // }, [timeList, howLong, date, selectedTime]);

  return (
    <>
      <Container fluid className="saoy_min_height px-0">
        <Row>
          <Col className="px-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard/frontpage">
                    <b>Etusivu</b>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Terapeutit / {profile?.first_name} {profile?.last_name}
                </li>
              </ol>
            </nav>
          </Col>
        </Row>
        <Row className="mt-4 pb-6">
          {/* <Col sm="12" md="7" lg="8" className="px-0"> */}
          <Col sm="12" lg="7" className="mx-1 px-0">
            <div className="shadow bg-primary p-4">
              <div className="card-horizontal">
                <div>
                  {profile?.profile_image && (
                    <>
                      <img
                        className="rounded-circle card_img"
                        src={profile?.profile_image || logo}
                        alt="Profile"
                        width={"150px"}
                        height={"150px"}
                      />
                      <Button
                        variant="info"
                        className="px-2 py-0 static_with_border profile_position__price"
                      >
                        <Typography variant="small">
                          {profile?.min_45_price}€ / tunti
                        </Typography>
                      </Button>
                    </>
                  )}
                  {!profile?.profile_image && (
                    <>
                      <img
                        className="rounded-circle card_img skeleton"
                        src={logo}
                        alt="Profile"
                        width={"150px"}
                        height={"150px"}
                      />
                      <Button
                        variant="info"
                        className="px-2 py-0 static_with_border profile_position__price"
                      >
                        <Typography variant="small">
                          {profile?.min_45_price}€ / tunti
                        </Typography>
                      </Button>
                    </>
                  )}
                </div>
                <div className="w-100 ml-4">
                  <Row className="pb-1 w-100">
                    <Col>
                      {profile?.first_name && profile?.last_name && (
                        <Typography inline variant={"h3"}>
                          {profile.first_name} {profile.last_name}
                          {profile?.finnish && (
                            <img
                              className="ml-2"
                              src={fi}
                              alt="flag"
                              height={"15px"}
                            />
                          )}
                          {profile?.swedish && (
                            <img
                              className="ml-2"
                              src={se}
                              alt="flag"
                              height={"15px"}
                            />
                          )}
                          {profile?.english && (
                            <img
                              className="ml-2"
                              src={gb}
                              alt="flag"
                              height={"15px"}
                            />
                          )}
                          {profile?.other_language && (
                            <Typography inline className="ml-2" variant="p">
                              {profile?.other_language}
                            </Typography>
                          )}
                        </Typography>
                      )}
                      {!profile?.first_name && !profile?.last_name && (
                        <div className="skeleton skeleton-title"></div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pb-1">
                      {profile.short_term_therapy_student && (
                        <Typography variant={"small"}>
                          Lyhytterapeuttiopiskelija
                        </Typography>
                      )}
                      {profile.psychotherapist_student && (
                        <Typography variant={"small"}>
                          Psykoterapeuttiopiskelija
                        </Typography>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {profile?.cities?.length === 1 &&
                        !profile?.only_remote_appointments && (
                          <Typography className="mt-0 pb-0" variant="small">
                            <img className="pr-1" src={geo} alt="geo" />
                            <b>
                              {enums?.addresses &&
                                enums?.addresses?.city &&
                                enums?.addresses.city
                                  .filter((l) => profile.cities.includes(l.key))
                                  .map((c) => c.value)}
                              {profile.whereabouts &&
                                `, ${profile.whereabouts}`}
                            </b>{" "}
                            {profile.remote_appointment && "+ etävastaanotto"}
                          </Typography>
                        )}
                      {profile?.cities?.length > 1 &&
                        !profile?.only_remote_appointments && (
                          <Typography className="mt-0 pb-0" variant="small">
                            <img className="pr-1" src={geo} alt="geo" />
                            <b>
                              {enums?.addresses &&
                                enums?.addresses?.city &&
                                enums?.addresses.city
                                  .filter((l) => profile.cities.includes(l.key))
                                  .map((c) => `${c.value}, `)}
                              {profile.whereabouts &&
                                `, ${profile.whereabouts}`}
                            </b>{" "}
                            {profile.remote_appointment && "+ etävastaanotto"}
                          </Typography>
                        )}
                      {profile?.cities?.length === 1 &&
                        profile?.only_remote_appointments && (
                          <Typography className="mt-0 pb-0" variant="small">
                            <img className="pr-1" src={geo} alt="geo" />
                            <b>
                              {enums?.addresses &&
                                enums?.addresses?.city &&
                                enums?.addresses.city
                                  .filter((l) => profile.cities.includes(l.key))
                                  .map((c) => c.value)}
                              {profile.whereabouts &&
                                `, ${profile.whereabouts}`}
                            </b>{" "}
                            {profile.only_remote_appointments &&
                              "vain etävastaanotto"}
                          </Typography>
                        )}
                      {profile?.cities?.length > 1 &&
                        profile?.only_remote_appointments && (
                          <Typography className="mt-0 pb-0" variant="small">
                            <img className="pr-1" src={geo} alt="geo" />
                            <b>
                              {enums?.addresses &&
                                enums?.addresses?.city &&
                                enums?.addresses.city
                                  .filter((l) => profile.cities.includes(l.key))
                                  .map((c) => `${c.value}, `)}
                              {profile.whereabouts &&
                                `, ${profile.whereabouts}`}
                            </b>{" "}
                            {profile.only_remote_appointments &&
                              "vain etävastaanotto"}
                          </Typography>
                        )}
                      {!profile?.cities && (
                        <div className="mt-0 pb-0 skeleton skeleton-title"></div>
                      )}

                      {/* {profile?.cities && (
                        <Typography className="pb-2" variant='small' bold><img className="pr-1" src={geo} alt="geo"  />{locations.filter(l => profile.cities.includes(l.key)).map(c => c.value)}, {profile.whereabouts} {profile.remote_appointment && '+ etävastaanotto'}</Typography>
                      )}
                      {!profile?.cities && !profile.whereabouts && (
                        <div className="skeleton skeleton-title"></div>
                      )} */}
                    </Col>
                  </Row>
                  {profile.kela_approved && (
                    <img
                      className=""
                      src={kela}
                      alt="kela_approved"
                      height={"30px"}
                    />
                  )}
                  {/* <Button variant="info" className="px-2 py-0 static_with_border "><Typography variant="small">Psykoterapia</Typography></Button>
                  <Button variant="info" className="px-2 py-0 static_with_border "><Typography variant="small">Sairaanhoitaja</Typography></Button> */}
                </div>
              </div>
              <div className="pt-4">
                <Typography className="pb-2" variant={"h3"}>
                  Koulutus
                </Typography>
                {profile.education && (
                  <Typography variant={"small"}>{profile.education}</Typography>
                )}
                {!profile.education && (
                  <>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                  </>
                )}
              </div>
              <div className="pt-5">
                <Typography className="pb-2" variant={"h3"}>
                  Kokemus ja osaaminen
                </Typography>
                {profile.experience && (
                  <Typography variant={"small"}>
                    {profile.experience}
                  </Typography>
                )}
                {!profile.experience && (
                  <>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                  </>
                )}
              </div>
              <div className="pt-5">
                <Typography className="pb-2" variant={"h3"}>
                  Kenelle?
                </Typography>
                {profile?.to_who && (
                  <>
                    {toWhos
                      .filter((t) => profile.to_who.includes(t.key))
                      .map((e) => (
                        <Button
                          variant="info"
                          className="px-1 py-0 static_with_border"
                        >
                          <Typography variant="small">{e.value}</Typography>
                        </Button>
                      ))}
                  </>
                )}
                {loading && (
                  <Row className="ml-1">
                    <div className="px-1 py-0 static_with_border skeleton skeleton-button"></div>
                    <div className="px-1 py-0 static_with_border skeleton skeleton-button"></div>
                    <div className="px-1 py-0 static_with_border skeleton skeleton-button"></div>
                  </Row>
                )}
              </div>
              <div className="pt-5">
                {profile?.therapy_types?.length !== 0 && therapyTypes && (
                  <>
                    <Typography className="pb-2" variant={"h3"}>
                      Psykoterapia
                    </Typography>
                    {therapyTypes
                      .filter((t) => profile.therapy_types.includes(t.key))
                      .map((e) => (
                        <Button
                          variant="info"
                          className="px-1 py-0 static_with_border"
                        >
                          <Typography variant="small">{e.value}</Typography>
                        </Button>
                      ))}
                  </>
                )}
                {profile?.therapy_types?.length !== 0 && loading && (
                  <Row className="ml-1">
                    <div className="px-1 py-0 static_with_border skeleton skeleton-button"></div>
                    <div className="px-1 py-0 static_with_border skeleton skeleton-button"></div>
                    <div className="px-1 py-0 static_with_border skeleton skeleton-button"></div>
                  </Row>
                )}
              </div>
              <div className="pt-5">
                <Typography className="pb-2" variant={"h3"}>
                  Millainen terapeutti olen?
                </Typography>
                {profile?.what_kind_of_therapist_i_am && (
                  <Typography variant={"small"}>
                    {profile.what_kind_of_therapist_i_am}
                  </Typography>
                )}
                {!profile?.what_kind_of_therapist_i_am && (
                  <>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                  </>
                )}
              </div>
              <div className="pt-5">
                <Typography className="pb-2" variant={"h3"}>
                  Mitä istuinnoillani tehdään?
                </Typography>
                {profile.session_content && (
                  <Typography variant={"small"}>
                    {profile.session_content}
                  </Typography>
                )}
                {!profile.session_content && (
                  <>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                    <div className="skeleton skeleton-text"></div>
                  </>
                )}
              </div>

              {profile.description && (
                <div className="pt-5 pb-4">
                  <Typography className="pb-2" variant={"h3"}>
                    Lisätietoja
                  </Typography>
                  {profile.description && (
                    <Typography variant={"small"}>
                      {profile.description}
                    </Typography>
                  )}
                  {!profile?.description && (
                    <>
                      <div className="skeleton skeleton-text"></div>
                      <div className="skeleton skeleton-text"></div>
                      <div className="skeleton skeleton-text"></div>
                    </>
                  )}
                </div>
              )}
            </div>
          </Col>

          {/* <Col sm="12" md="5" lg="4" style={{ minWidth: "380px" }}> */}
          <Col
            sm="12"
            lg="4"
            // style={{ minWidth: "380px" }}
            className="mx-1 px-0"
          >
            <div className="shadow bg-primary p-4">
              <div className="ml-1">
                <Typography className="pb-2" variant="h3">
                  Aloita valitsemalla päivä
                </Typography>
                <Calendar
                  tileDisabled={tileDisabled}
                  onChange={dateHandler}
                  value={date}
                  tileContent={tileContent}
                  next2Label={null}
                  prev2Label={null}
                />
              </div>

              <Typography className="mt-4 ml-1" variant="h3">
                Valitse aika
              </Typography>

              <Row className="w-100 profile_calender_selection_area ml-1 mt-2 p-3">
                <Row className="w-100">
                  <Col>
                    <Typography variant={"display5"}>
                      Valitse terapia
                    </Typography>
                  </Col>
                </Row>
                <Row className="my-2 mr-2 w-100">
                  <Col>
                    <Select
                      className=""
                      isDisabled={
                        events?.find(
                          (event) =>
                            moment(event.start_time).format("l") ===
                            moment(date).format("l")
                        )
                          ? false
                          : true
                      }
                      placeholder={"Valitse terapia"}
                      options={therapiesList}
                      selected={selectedTherapy}
                      onChange={therapyHandler}
                    />
                  </Col>
                </Row>

                <Row className="w-100">
                  <Col>
                    <Typography variant={"display5"}>Valitse kesto</Typography>
                  </Col>
                </Row>
                <Row className="my-2 mr-2 w-100">
                  <Col>
                    <Select
                      className=""
                      isDisabled={
                        events?.find(
                          (event) =>
                            moment(event.start_time).format("l") ===
                            moment(date).format("l")
                        )
                          ? false
                          : true
                      }
                      placeholder={"Valitse kesto"}
                      options={howLongList}
                      selected={howLong}
                      onChange={howLongHandler}
                    />
                  </Col>
                </Row>

                <Row className="w-100">
                  <Col>
                    <Typography variant="display5">Vapaat ajat</Typography>
                  </Col>
                </Row>
                <Row className="">
                  <Col>
                    {/* <ul className="py-2 px-0">
                        {events.filter(d => moment(date).format('l') === moment(d.start_time).format('l')).map((e) => (
                          <ol className="py-2 px-0">{moment(e.start_time).format('LT')} - {moment(e.end_time).format('LT')}</ol>
                        ))}
                      </ul> */}
                    <ul className="px-0">
                      {timeList?.length > 0 &&
                        timeList
                          .sort((a, b) => a.label - b.label)
                          .map((t, index) => {
                            return (
                              <ol key={index} className="py-2 px-0">
                                {t.label}
                              </ol>
                            );
                          })}
                      {!!howLong && timeList?.length === 0 && (
                        <Typography bold className="text-danger">
                          Ei vapaita aikoja valinnalla
                        </Typography>
                      )}
                    </ul>
                  </Col>
                </Row>

                <Row className="my-2 mr-2 w-100">
                  <Col className="">
                    <Select
                      isDisabled={
                        // events?.find(
                        //   (event) =>
                        //     moment(event.start_time).format("l") ===
                        //     moment(date).format("l")
                        // )
                        //   ? false
                        //   : true
                        timeList.length === 0
                      }
                      placeholder={"Valitse aika"}
                      value={selectedTime}
                      options={timeList}
                      onChange={selectedTimeHandler}
                    />
                  </Col>
                </Row>

                <Row className="w-100">
                  <Col>
                    <Typography className="mt-2" variant="display5">
                      Valittu {date.getDate()}.{date.getMonth() + 1}.
                      {date.getFullYear()}{" "}
                      {selectedTime.label === undefined
                        ? ""
                        : `kello ${timeReducer(selectedTime.label)}`}{" "}
                      {howLong?.value === 45 && `(${profile?.min_45_price} €)`}{" "}
                      {howLong?.value === 90 && `(${profile?.min_90_price} €)`}
                    </Typography>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <Typography className="mt-2" variant="display5">
                      Lisätiedot halutessa
                    </Typography>
                  </Col>
                </Row>
                <Row className="w-100">
                  <Col className="w-100">
                    <Input
                      className="w-100"
                      disabled={
                        events?.find(
                          (event) =>
                            moment(event.start_time).format("l") ===
                            moment(date).format("l")
                        )
                          ? false
                          : true
                      }
                      style={{ height: "38px" }}
                      onChange={(e) => setCalendarInfo(e.target.value)}
                    ></Input>
                  </Col>
                </Row>

                {profile?.remote_appointment &&
                  !profile?.only_remote_appointments && (
                    <>
                      <Row className="w-100 mt-2">
                        <Col>
                          <Typography variant={"display5"}>
                            Valitse vastaanottopaikka
                          </Typography>
                        </Col>
                      </Row>
                      <Row className="mr-2 w-100">
                        <Col>
                          <Select
                            className=""
                            isDisabled={
                              events?.find(
                                (event) =>
                                  moment(event.start_time).format("l") ===
                                  moment(date).format("l")
                              )
                                ? false
                                : true
                            }
                            placeholder={"Vastaanottopaikka"}
                            options={reseptionLocationChoises}
                            selected={reseptionLocation}
                            onChange={receptionLocationHandler}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                {profile?.remote_appointment &&
                  profile?.only_remote_appointments && (
                    <>
                      <Row className="w-100 my-2">
                        <Col>
                          <Typography variant={"display5"}>
                            Etävastaanotto
                          </Typography>
                        </Col>
                      </Row>
                    </>
                  )}

                {profile?.kela_approved && (
                  <>
                    <Row className="w-100 mt-2">
                      <Col>
                        <Typography variant={"display5"}>Korvaukset</Typography>
                      </Col>
                    </Row>
                    <Row className="mr-2 w-75">
                      <Col>
                        <Select
                          className=""
                          placeholder={"Valitse"}
                          options={compensationList}
                          selected={usingKelaApprove}
                          onChange={compensationHandler}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                <Row className="w-100 mt-4 mx-auto">
                  <Button
                    className="mt-4 btn btn-block"
                    variant="success"
                    disabled={!isSubmitting}
                    onClick={submitHandler}
                  >
                    VARAA JA MAKSA
                  </Button>
                  {Boolean(errors["general"]) && (
                    <Typography className="text-danger">
                      {errors["general"]}
                    </Typography>
                  )}
                </Row>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      {/* VARAAMINEN JA MAKSUPALVELUUN SIIRTYMINEN */}
      <Modal isOpen={modal} centered>
        <div className="modal-header p-0 mb-3 d-flex justify-content-center">
          <Typography className="" variant="h3" bold>
            Maksun välittää STRIPE-palvelu
          </Typography>
        </div>
        <div className="modal-body p-0">
          <Row>
            <Col>
              <Row className="mt-2">
                <Col>
                  <Typography bold variant={"display5"}>
                    Olette varaamassa aikaa:
                  </Typography>
                  <Typography className="mt-2" variant={"display5"}>
                    {profile?.first_name} {profile?.last_name}
                  </Typography>
                  <Typography variant={"display5"}>
                    {date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}
                  </Typography>
                  <Typography variant={"display5"}>
                    kello {timeReducer(selectedTime.label)}
                  </Typography>
                  <Typography className="mt-2" bold variant={"display5"}>
                    Hinta{" "}
                    {howLong?.value === 45 && `${profile?.min_45_price} €`}{" "}
                    {howLong?.value === 90 && `${profile?.min_90_price} € `} sis
                    alv
                  </Typography>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col
                  className="text-center mx-3"
                  style={{ background: "#FF3B64" }}
                >
                  <Typography inline className="py-4 text-white" bold>
                    Maksutapa:&nbsp;
                  </Typography>
                  <Typography inline className="py-4 text-white">
                    Yleisimmät pankkikortit ja Klarna
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="modal-footer p-0 mt-5 d-flex justify-content-between">
          <Button
            color="light"
            onClick={cancelReservation}
            style={{ height: "50px" }}
          >
            <Typography bold className="text-white">
              Peruuta
            </Typography>
          </Button>

          <Button
            color="success"
            style={{ height: "50px" }}
            onClick={linkHandler}
          >
            <Typography bold className="text-white">
              Siirry maksamaan
            </Typography>
          </Button>
        </div>
      </Modal>

      {/* RETURN FROM SUCCESSFUL PAYMENT TRANSACTION */}
      <Modal
        isOpen={returnSuccessfulModal}
        // toggle={toggleReturnSuccessfulModal}
        centered
      >
        <div className="modal-header p-0 mb-3">
          <Typography className="text-black" variant="h3" bold>
            Ajanvaraus onnistui
          </Typography>
        </div>
        <div className="modal-body p-0">
          <Container>
            <Row>
              <Col>
                <Row className="mt-2">
                  <Col>
                    <Row>
                      <Typography className="mb-2" variant={"display5"}>
                        Olet varannut ja maksanut seuraavan ajan onnistuneesti:
                      </Typography>
                    </Row>
                    <Row>
                      <Typography bold variant={"display5"}>
                        Aika:&nbsp;
                      </Typography>
                      <Typography variant={"display5"}>
                        {moment(
                          reservedEvents?.find((event) => event.id == eventID)
                            ?.start_time
                        ).format("dddd DD.MM.YYYY")}
                        &nbsp;
                        {timeReducerTwoTimes(
                          moment(
                            reservedEvents?.find((event) => event.id == eventID)
                              ?.start_time
                          ),
                          moment(
                            reservedEvents?.find((event) => event.id == eventID)
                              ?.end_time
                          )
                        )}
                        {/* {moment(reservedEvents?.find(event => event.id == eventID)?.end_time).format("HH:mm")} */}
                      </Typography>
                    </Row>
                    <Row>
                      <Typography bold variant={"display5"}>
                        Paikka:&nbsp;
                      </Typography>
                      <Typography variant={"display5"}>
                        {reservedEvents?.find((event) => event.id == eventID)
                          ?.use_remote_appointment
                          ? "Etävastaanotto (Terapeutti on yhteydessä sinuun piakkoin)"
                          : `Paikan päällä (${profile?.street_address}, ${profile?.city})`}{" "}
                        &nbsp;
                      </Typography>
                    </Row>
                    <Row>
                      <Typography bold variant={"display5"}>
                        Terapeutti:&nbsp;
                      </Typography>
                      <Typography variant={"display5"}>
                        {profile?.first_name} {profile?.last_name},{" "}
                        {profile?.phone}
                      </Typography>
                    </Row>
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col
                    className="text-center"
                    style={{ background: "#0ED9D9" }}
                  >
                    <Typography className="py-2" variant={"display5"}>
                      Olemme lähettäneet maksukuitin ja varauksenne tiedot
                      antamaanne sähköpostiin
                    </Typography>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="modal-footer p-0 mt-5 d-flex justify-content-between">
          <Button
            color="darker"
            style={{ width: "180px" }}
            onClick={() => toggleReturn(profile?.id)}
          >
            <Typography bold>Sulje</Typography>
          </Button>
        </div>
      </Modal>

      {/* PALUU EPÄONNISTUNEESTA MAKSUTAPAHTUMASTA */}
      <Modal
        isOpen={returnFailedModal}
        toggle={toggleReturnFailedModal}
        centered
      >
        <div className="modal-header p-0 mb-3">
          <Typography className="text-black" variant="h3" bold>
            Ajanvaraus epäonnistui
          </Typography>
        </div>
        <div className="modal-body p-0">
          <Row>
            <Col>
              <Row className="mt-2">
                <Col>
                  <Typography bold variant={"display5"}>
                    Tapahtumassa tapahtui virhe, kokeile uudelleen.
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="modal-footer p-0 mt-5 d-flex justify-content-between">
          <Button
            color="default"
            style={{ width: "180px" }}
            onClick={toggleReturnFailedModal}
          >
            <Typography bold>Sulje</Typography>
          </Button>
        </div>
      </Modal>
    </>
  );
};

const defaultValues = {
  id: null,
  name: "",
};

const TherapistProfileFormik = withFormik({
  validateOnMount: true,
  mapPropsToValues: (props) => {
    const { preSetValues } = props;
    if (preSetValues) {
      return preSetValues;
    } else {
      return defaultValues;
    }
  },

  handleSubmit: (values, { setSubmitting, setErrors, props }) => {
    const {
      t,
      selectedTime,
      calendarInfo,
      secondEvent,
      usingKelaApprove,
      reseptionLocation,
      selectedTherapy,
    } = props;

    let title = "Varattu aika";

    let id = selectedTime.value;

    let therapy = selectedTherapy?.value;

    let reserved = true;

    let description = calendarInfo;

    let data = {
      ...values,
      reserved: reserved,
      title: title,
      description: description,
      use_kela: usingKelaApprove?.value,
      use_remote_appointment: reseptionLocation?.value,
      therapy_type: therapy,
    };

    if (secondEvent?.length === 1) {
      data = {
        ...values,
        reserved: reserved,
        title: title,
        description: description,
        end_time: secondEvent[0].end_time,
        use_kela: usingKelaApprove?.value,
        use_remote_appointment: reseptionLocation?.value,
        therapy_type: therapy,
      };
    }

    httpUpdateEvent(id, data).then(
      (res) => {
        setSubmitting(false);
        // props.notify({ title:t("Aika"), message:t("Varattu onnistuneesti")})
      },
      (error) => {
        setSubmitting(false);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          });
        } else {
          setErrors({
            general: t(
              "Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen."
            ),
          });
        }
      }
    );
    if (secondEvent?.length === 1) {
      httpDeleteEvent(secondEvent[0].id);
    }
  },
  displayName: "BasicForm",
})(TherapistProfile);

const TherapistProfileView = ({ match, history }) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(null);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [profile, setProfile] = useState({});
  const [reserved, setReserved] = useState();
  const [events, setEvents] = useState([]);
  const [reservedEvents, setReservedEvents] = useState([]);
  const [selectedTime, setSelectedTime] = useState([]);
  const [therapyTypes, setTherapyTypes] = useState([]);
  const [toWhos, setToWhos] = useState([]);
  const [locations, setLocations] = useState([]);
  const [reload, setReload] = useState(false);
  const [calendarInfo, setCalendarInfo] = useState("");
  const [howLong, setHowLong] = useState();
  const [secondEvent, setsecondEvent] = useState();
  const [reseptionLocation, setReseptionLocation] = useState();
  const [usingKelaApprove, setUsingKelaApprove] = useState();
  const [selectedTherapy, setSelectedTherapy] = useState();

  useEffect(() => {
    setLoading(true);
    if (match && match?.params?.id) {
      httpGetTherapist(match.params.id).then((res) => {
        setProfile(res.data);
      });
    }
  }, [match]);

  useEffect(() => {
    setLoading(true);
    if (profile.id !== undefined) {
      httpGetEnums().then((res) => {
        setTherapyTypes(res.data.users.therapy_type);
        setToWhos(res.data.users.to_who);
        setLocations(res.data.addresses.city);
        setLoading(false);
      });
    }
  }, [profile]);

  useEffect(() => {
    if (profile.id !== undefined) {
      setLoading(true);
      httpGetEvents({ user: profile.id, reserved: false }).then((res) => {
        setEvents(res?.data);
      });
      httpGetEvents({ user: profile.id, reserved: true }).then((res) => {
        setReservedEvents(res?.data);
        setReload(false);
      });
    }
  }, [profile, reload]);

  // if (loading) {
  //   return (
  //     <Container fluid>
  //       <Spinner />
  //     </Container>
  //   )
  // }

  return (
    <Container fluid className="mt-4">
      <TherapistProfileFormik
        loading={loading}
        history={history}
        t={t}
        preSetValues={preSetValues}
        notify={notify}
        profile={profile}
        setProfile={setProfile}
        reserved={reserved}
        setReserved={setReserved}
        events={events}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        therapyTypes={therapyTypes}
        setTherapyTypes={setTherapyTypes}
        toWhos={toWhos}
        setToWhos={setToWhos}
        locations={locations}
        setLocations={setLocations}
        setReload={setReload}
        calendarInfo={calendarInfo}
        setCalendarInfo={setCalendarInfo}
        howLong={howLong}
        setHowLong={setHowLong}
        secondEvent={secondEvent}
        setsecondEvent={setsecondEvent}
        reseptionLocation={reseptionLocation}
        setReseptionLocation={setReseptionLocation}
        usingKelaApprove={usingKelaApprove}
        setUsingKelaApprove={setUsingKelaApprove}
        reservedEvents={reservedEvents}
        selectedTherapy={selectedTherapy}
        setSelectedTherapy={setSelectedTherapy}
      />
    </Container>
  );
};

export default TherapistProfileView;
