import React, { useCallback, useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";

import { httpGetTherapists } from "services/users";

import { Row, Container } from "stories/layout";

import DropdownView from "components/DropdownView";
import LoaderScreen from "components/LoaderScreen";
import useWindowDimensions from "components/useWindowDimensions";
import ProfileCard from "components/ProfileCard";
import LoadMoreButton from "components/LoadMoreButton";

const FrontPage = ({ history }) => {
  // const { t } = useTranslation();
  const [initialLoading, setInitialLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [selectedTherapys, setSelectedTherapys] = useState([]);
  const [selectedTherapyTypes, setSelectedTherapyTypes] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState([]);
  const [selectedToWhos, setSelectedToWhos] = useState([]);
  const [hasAvailableSlots, setHasAvailableSlots] = useState(false);
  const [remoteAppointment, setRemoteAppointment] = useState(false);
  const [timeEnd, setTimeEnd] = useState(false);
  const { width } = useWindowDimensions();
  const [hasMore, setHasMore] = useState(true);

  const [offset, setOffset] = useState(0);
  const limit = 10;

  const fetchProfiles = useCallback(
    async (isLoadMore = false) => {
      if (!hasMore && isLoadMore) return;

      if (isLoadMore) {
        setLoadMoreLoading(true);
      } else {
        setInitialLoading(true);
      }

      setTimeout(() => {
        setTimeEnd(true);
      }, 5000);

      try {
        const response = await httpGetTherapists({
          therapist: true,
          stripe_account_completed: true,
          therapies: selectedTherapys.key,
          therapy_types: selectedTherapyTypes.key,
          provinces: selectedProvince.key,
          cities: selectedCity.key,
          to_who: selectedToWhos.key,
          remote_appointment: remoteAppointment,
          limit,
          offset: isLoadMore ? offset : 0,
        });

        const filteredProfilesHelper = response?.data?.results?.filter(
          (profile) => profile.education && profile.education.length > 0
        );

        // Update profiles by merging old data with new data
        if (isLoadMore) {
          setProfiles((prevProfiles) => [
            ...prevProfiles,
            ...filteredProfilesHelper,
          ]);
          setOffset((prevOffset) => prevOffset + limit);
        } else {
          setProfiles(filteredProfilesHelper);
          setOffset(limit);
        }

        // Check if more data exists
        setHasMore(!!response?.data?.next);
      } catch (error) {
        console.error("Error fetching profiles", error);
      } finally {
        if (isLoadMore) {
          setLoadMoreLoading(false);
        } else {
          setInitialLoading(false);
        }
      }
    },
    [
      hasMore,
      offset,
      selectedTherapys,
      selectedTherapyTypes,
      selectedCity,
      selectedProvince,
      selectedToWhos,
      remoteAppointment,
    ]
  );

  useEffect(() => {
    // Reset the offset and clear the profiles when a new search begins / filters updated
    setOffset(0);
    setProfiles([]);
    fetchProfiles(false);
  }, [
    selectedTherapys,
    selectedTherapyTypes,
    selectedCity,
    selectedProvince,
    selectedToWhos,
    remoteAppointment,
  ]);

  // Available times checkbox filter
  useEffect(() => {
    let filteredProfilesHelper = profiles;

    if (hasAvailableSlots) {
      filteredProfilesHelper = filteredProfilesHelper.filter(
        (p) => p.next_free_time_in_days !== null
      );
    }

    setFilteredProfiles(filteredProfilesHelper);
  }, [hasAvailableSlots, profiles]);

  return (
    <>
      <DropdownView
        setSelectedCity={setSelectedCity}
        setSelectedProvince={setSelectedProvince}
        selectedTherapys={selectedTherapys}
        setSelectedTherapys={setSelectedTherapys}
        setSelectedTherapyTypes={setSelectedTherapyTypes}
        setSelectedToWhos={setSelectedToWhos}
        setRemoteAppointment={setRemoteAppointment}
        setHasAvailableSlots={setHasAvailableSlots}
      />

      <Container className="mt-5 px-0 mx-auto saoy_min_height">
        {initialLoading ? (
          <LoaderScreen />
        ) : (
          <Row>
            {filteredProfiles.map((profile) => (
              <ProfileCard
                key={profile.id}
                profile={profile}
                onClick={(id) => history.push(`/dashboard/profile/${id}`)}
                selectedTherapys={selectedTherapys}
                selectedTherapyTypes={selectedTherapyTypes}
                selectedToWhos={selectedToWhos}
                timeEnd={timeEnd}
                width={width}
              />
            ))}
            <LoadMoreButton
              hasMore={hasMore}
              loadMoreLoading={loadMoreLoading}
              onClick={() => fetchProfiles(true)}
            />
          </Row>
        )}
      </Container>
    </>
  );
};

export default FrontPage;
