import { Button } from "stories/components";

const LoadMoreButton = ({ hasMore, loadMoreLoading, onClick }) => {
  return (
    <div className="w-100 d-flex align-items-center justify-content-center mb-4">
      {hasMore && !loadMoreLoading && (
        <Button
          className="text-center text-white"
          onClick={onClick}
          style={{
            backgroundColor: "#1F63E1",
            border: 0,
            borderRadius: 25,
          }}
        >
          Lataa lisää
        </Button>
      )}

      {loadMoreLoading && (
        <p className="loading-text bold">Ladataan lisää profiileja...</p>
      )}
    </div>
  );
};

export default LoadMoreButton;
