import { Button, Typography } from "stories/components";
import { Col, Card, CardBody } from "stories/layout";

import logo from "assets/images/profileImg.png";
import geo from "assets/img/icons/common/geo-alt-fill.svg";
import fi from "assets/img/icons/flags/fi.png";
import se from "assets/img/icons/flags/se.png";
import gb from "assets/img/icons/flags/GB.png";
import { useAppContext } from "contexts/AppContext";

const getLimitedTextString = (textString, limit) => {
  if (textString.length > limit) {
    return textString.substring(0, limit - 3) + "...";
  } else {
    return textString;
  }
};

const ProfileCard = ({
  profile,
  onClick,
  selectedTherapys,
  selectedTherapyTypes,
  selectedToWhos,
  timeEnd,
  width,
}) => {
  const { enums } = useAppContext();

  return (
    <Col sm="12 " lg="6" xl="4" key={profile.id}>
      <Card
        className="mx-0 px-0"
        onClick={() => onClick(profile?.id)}
        style={{
          maxWidth: "570px",
          minHeight: "210px",
          cursor: "pointer",
        }}
      >
        <div className="card-horizontal">
          <div className="">
            {profile?.profile_image && (
              <>
                <img
                  className="ml-3 my-4 rounded-circle card_img"
                  src={profile?.profile_image || logo}
                  alt="Profile"
                  width={width > 600 ? "150px" : "100px"}
                  height={width > 600 ? "150px" : "100px"}
                />
                <Button
                  variant="info"
                  // className="px-2 py-0 static_with_border card_position__price"
                  className="px-2 py-0 static_with_border"
                  style={{
                    transform:
                      width > 600
                        ? "translate(-115px, 155px)"
                        : "translate(-90px, 120px)",
                    position: "absolute",
                  }}
                >
                  <Typography variant="small">
                    {profile?.min_45_price}€ / tunti
                  </Typography>
                </Button>
              </>
            )}
            {!profile?.profile_image && (
              <>
                <img
                  className="ml-3 my-4 rounded-circle card_img skeleton"
                  src={logo}
                  alt="Profile"
                  width={width > 600 ? "150px" : "100px"}
                  height={width > 600 ? "150px" : "100px"}
                />
                <Button
                  variant="info"
                  // className="px-2 py-0 static_with_border card_position__price"
                  className="px-2 py-0 static_with_border"
                  style={{
                    transform:
                      width > 600
                        ? "translate(-115px, 155px)"
                        : "translate(-90px, 120px)",
                    position: "absolute",
                  }}
                >
                  <Typography variant="small">
                    {profile?.min_45_price}€ / tunti
                  </Typography>
                </Button>
              </>
            )}
          </div>

          <CardBody>
            <div className="card_freetime_area">
              {profile?.next_free_time_in_days > 0 && (
                <Button
                  variant={
                    // profile?.next_free_time_in_days < 7
                    "success"
                    // : "warning"
                  }
                  className="float-right m-0 px-2 py-0 static_without_border"
                >
                  <Typography variant="small">
                    aikoja {profile?.next_free_time_in_days} vrk sisällä
                  </Typography>
                </Button>
              )}
              {profile?.next_free_time_in_days === 0 && (
                <Button
                  variant={"success"}
                  className="float-right m-0 px-2 py-0 static_without_border"
                >
                  <Typography variant="small">aikoja tänäänkin</Typography>
                </Button>
              )}
              {profile?.next_free_time_in_days === null && (
                <Button
                  variant={"warning"}
                  className="float-right m-0 px-2 py-0 static_without_border"
                >
                  <Typography variant="small">Ei aikoja saatavilla</Typography>
                </Button>
              )}
              {profile?.english && (
                <img
                  className="mr-1 float-right"
                  src={gb}
                  alt="flag"
                  height={"13px"}
                />
              )}
              {profile?.swedish && (
                <img
                  className="mr-1 float-right"
                  src={se}
                  alt="flag"
                  height={"13px"}
                />
              )}
              {profile?.finnish && (
                <img
                  className="mr-1 float-right"
                  src={fi}
                  alt="flag"
                  height={"13px"}
                />
              )}
            </div>

            {profile?.first_name && profile?.last_name && (
              <Typography inline variant={"h4"} className="card-title">
                {profile.first_name} {profile.last_name}
              </Typography>
            )}
            {!profile?.first_name && !profile?.last_name && (
              <div className="card-title skeleton skeleton-title"></div>
            )}
            {profile?.psychotherapist_student && (
              <Typography variant={"small"}>
                Psykoterapeuttiopiskelija
              </Typography>
            )}
            {profile?.short_term_therapy_student && (
              <Typography variant={"small"}>
                Lyhytterapeuttiopiskelija
              </Typography>
            )}

            {profile?.cities?.length === 1 &&
              !profile?.only_remote_appointments && (
                <Typography className="mt-0 pb-0" variant="small">
                  <img className="pr-1" src={geo} alt="geo" />
                  <b>
                    {enums?.addresses &&
                      enums?.addresses?.city &&
                      enums?.addresses.city
                        .filter((l) => profile.cities.includes(l.key))
                        .map((c) => c.value)}
                    {profile.whereabouts && `, ${profile.whereabouts}`}
                  </b>{" "}
                  {profile.remote_appointment && "+ etävastaanotto"}
                </Typography>
              )}
            {profile?.cities?.length > 1 && !profile?.only_remote_appointments && (
              <Typography className="mt-0 pb-0" variant="small">
                <img className="pr-1" src={geo} alt="geo" />
                <b>
                  {enums?.addresses &&
                    enums?.addresses?.city &&
                    enums?.addresses.city
                      .filter((l) => profile.cities.includes(l.key))
                      .map((c) => `${c.value}, `)}
                  {profile.whereabouts && `, ${profile.whereabouts}`}
                </b>{" "}
                {profile.remote_appointment && "+ etävastaanotto"}
              </Typography>
            )}
            {profile?.cities?.length === 1 &&
              profile?.only_remote_appointments && (
                <Typography className="mt-0 pb-0" variant="small">
                  <img className="pr-1" src={geo} alt="geo" />
                  <b>
                    {enums?.addresses &&
                      enums?.addresses?.city &&
                      enums?.addresses.city
                        .filter((l) => profile.cities.includes(l.key))
                        .map((c) => c.value)}
                    {profile.whereabouts && `, ${profile.whereabouts}`}
                  </b>{" "}
                  {profile.only_remote_appointments && "vain etävastaanotto"}
                </Typography>
              )}
            {profile?.cities?.length > 1 && profile?.only_remote_appointments && (
              <Typography className="mt-0 pb-0" variant="small">
                <img className="pr-1" src={geo} alt="geo" />
                <b>
                  {enums?.addresses &&
                    enums?.addresses?.city &&
                    enums?.addresses.city
                      .filter((l) => profile.cities.includes(l.key))
                      .map((c) => `${c.value}, `)}
                  {profile.whereabouts && `, ${profile.whereabouts}`}
                </b>{" "}
                {profile.only_remote_appointments && "vain etävastaanotto"}
              </Typography>
            )}
            {!profile?.cities && (
              <div className="mt-0 pb-0 skeleton skeleton-title"></div>
            )}

            {profile?.education && (
              <Typography variant={"small"} className="card-text pt-1 mb-2">
                {getLimitedTextString(`${profile?.education}`, 140)}
              </Typography>
            )}
            {!profile?.education && (
              <>
                <div className="card-text pt-1 skeleton skeleton-text"></div>
                <div className="card-text pt-1 skeleton skeleton-text"></div>
                <div className="card-text pt-1 skeleton skeleton-text"></div>
              </>
            )}
          </CardBody>

          <div
            className={`${width < 500 && ""}`}
            style={{
              transform:
                width > 600
                  ? "translate(190px, 165px)"
                  : width >= 500
                  ? "translate(138px, 165px)"
                  : "",

              position: "absolute",
              bottom: width < 500 && 5,
              left: width < 500 && 5,
            }}
          >
            {enums?.users?.therapy &&
              enums?.users?.therapy_type &&
              enums?.users?.to_who && (
                <>
                  {profile?.therapies.length > 0 && (
                    <>
                      {selectedTherapys.value === undefined ||
                      selectedTherapys.value === "" ? (
                        <Button
                          variant="info"
                          className=" px-1 py-0 static_with_border"
                        >
                          <Typography variant="small">
                            {getLimitedTextString(
                              `${enums?.users?.therapy
                                .filter((t) => profile?.therapies[0] === t.key)
                                .map((c) => c.value)}`,
                              20
                            )}
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="info"
                          className=" px-1 py-0 static_with_border"
                        >
                          <Typography variant="small">
                            {getLimitedTextString(
                              `${selectedTherapys.value}`,
                              20
                            )}
                          </Typography>
                        </Button>
                      )}
                    </>
                  )}
                  {profile?.therapies.length === 0 && !timeEnd && (
                    <Button className="static_with_border skeleton skeleton-button"></Button>
                  )}

                  {profile?.therapy_types.length > 0 && (
                    <>
                      {selectedTherapyTypes.value === undefined ||
                      selectedTherapyTypes.value === "" ? (
                        <Button
                          variant="info"
                          className=" px-1 py-0 static_with_border"
                        >
                          <Typography variant="small">
                            {getLimitedTextString(
                              `${enums?.users?.therapy_type
                                .filter(
                                  (t) => profile?.therapy_types[0] === t.key
                                )
                                .map((c) => c.value)}`,
                              20
                            )}
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="info"
                          className=" px-1 py-0 static_with_border"
                        >
                          <Typography variant="small">
                            {getLimitedTextString(
                              `${selectedTherapyTypes.value}`,
                              20
                            )}
                          </Typography>
                        </Button>
                      )}
                    </>
                  )}
                  {profile?.therapy_types.length === 0 && !timeEnd && (
                    <Button className="static_with_border skeleton skeleton-button"></Button>
                  )}

                  {profile?.to_who.length > 0 && (
                    <>
                      {selectedToWhos.value === undefined ||
                      selectedToWhos.value === "" ? (
                        <Button
                          variant="info"
                          className=" px-1 py-0 static_with_border"
                        >
                          <Typography variant="small">
                            {getLimitedTextString(
                              `${enums?.users?.to_who
                                .filter((t) => profile?.to_who[0] === t.key)
                                .map((c) => c.value)}`,
                              20
                            )}
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="info"
                          className=" px-1 py-0 static_with_border"
                        >
                          <Typography variant="small">
                            {getLimitedTextString(
                              `${selectedToWhos.value}`,
                              20
                            )}
                          </Typography>
                        </Button>
                      )}
                    </>
                  )}
                  {profile?.to_who.length === 0 && !timeEnd && (
                    <Button className="static_with_border skeleton skeleton-button"></Button>
                  )}
                </>
              )}
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default ProfileCard;
